import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h3>Hello</h3>
    <p>Welcome to the official Gembe & Watson site.</p>
    <p>Gembe & Watson is a private estate established in 2009 which funds the public, charitable and private activites of its owner and family.</p>
  </Layout>
)

export default IndexPage
